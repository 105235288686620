<div class="section-gap">
  <div class="container">
    <div class="section-title-wrapper mb-5">
      <div class="row">
        <div class="col-sm-12 col-md-9 mx-auto text-center">
          <h3>
            Erasmus+ project, KA2, Exchange of good practices
            <br />
            TITLE: My Cultural Heritage & My Future
          </h3>
          <h5>Acronym: MCMF</h5>
          <h5>Project Duration: 24 months, 01-02-2020 to 31-01-2022</h5>
          <h5>Summary</h5>
          <p>
            Our project is situated in the context of the European Year of
            Cultural Heritage (as well as thinking about Rijeka that will be the
            European Capital of Culture in Croatia in 2020) and the contribution
            of Cultural Sector to the employment of young people. How is this
            project contributing to this main aim of the youth employment?
            Through a European platform with online tools for youth workers,
            employment agents and volunteers who work daily with young people.
          </p>
          <p>
            For this reason, we identified the need to train youth workers,
            employment agents and even volunteers to equip them with the
            necessary tools to initiate and direct activities aimed at young
            people, such as employment workshops and personalized guidance. The
            project includes the exchange of good practices, the implementation
            of the network at European level to facilitate the work of young
            people with skills related to the cultural field, the training of
            youth leaders and the development of a European platform with online
            tools for youth workers and employment agents to inspire them and
            contribute to the dissemination of work on cultural heritage among
            young people.
          </p>
          <p>
            The promotion of work in the field of cultural heritage is relevant,
            since statistical data show that young people are very motivated to
            use their energy and creativity to develop work related to European
            cultural heritage.
          </p>
          <p>
            The transnational dimension represents the core of our project,
            because it offers the possibility for young workers from different
            parts of Europe to share experiences, learn what others do, think
            about how to adapt the methods of others in the workplace and
            implement their achievements.
          </p>
          <p>
            To get the project objectives, we are six partners: Teatro Sol y
            Tierra from Spain, Diverse Youth Network Association from Hungary,
            Kocaturk Training and Consulting from Turkey, Regain from France,
            Meraki from Croatia and A.I.D from Greece. Among the six
            organisations we'll get our common aims and will help European youth
            because young people are, also, our heritage.
          </p>
        </div>
      </div>
    </div>

    <div class="section-title-wrapper mb-5">
      <div class="row">
        <div class="col-sm-12 col-md-9 mx-auto text-center">
          <h5>2 nd TPM at Pecs, Hungary</h5>

          <div style="display: flex; align-items: center">
            <img style="width: 100%" src="assets/img/pecs.jpeg" />
          </div>

          <p>
            On September from 17 to 18 of 2021, 10 representatives came together
            for the 2nd transnational project meeting in Pecs, Hungary. The
            project partners met at the offices of the Diverse Youth Network
            organization and discussed its progress and next steps.
          </p>
          <p>
            The representatives reviewed the finished Learning Teaching Training
            Activity in Rijeka and analyzed the next steps and responsibilities
            of each partner. In the context of the discussion and exchange of
            views, all agreed that each organization to implement at least 2
            training sessions and to create a document that will include,
            describe, and analyze the activities and methodologies and the
            experiences and tools related to cultural heritage that took place
            in Rijeka for the L/T/T activity.
          </p>
          <p>
            On the second day, partners discussed the dissemination activities
            that will take place during the next months and the actions for the
            sustainability of the project. Finally, staff members from the
            partner organizations had the opportunity to help preparations and
            participate themselves in the festival “Freedom of My Identity Human
            Rights Festival” to spread our message, and talk about the goals and
            actions of the project MCMF.
          </p>
        </div>
      </div>
    </div>

    <div class="section-title-wrapper mb-5">
      <div class="row">
        <div class="col-sm-12 col-md-9 mx-auto text-center">
          <h5>3rd Transnational Project meeting in Valdepielagos, Spain</h5>

          <div style="display: flex; align-items: center">
            <img style="width: 100%" src="assets/img/madrid2.jpeg" />
          </div>

          <p>
            14 representatives of the project came together for the 3 rd
            transnational project meeting in Valdepielagos, Spain, in March 2022
            for the MCMF project.
          </p>
          <p>
            Each partner analyzed and explained to the other participants what
            had been done so far and what was still left to be completed.
            Outcomes from the multiplier events have also discussed, providing
            fruitful insights and implications and contributing to the project’s
            objectives.
          </p>
          <p>
            Both ideas and proposals were well received, and the main goal for
            the near future is the configuration of the educational toolkit
            based on the examples and practices of every country&#39;s
            participant. Thus, project partners agreed to follow a holistic and
            functional methodological approach to finalize the project and its
            outputs, as well as the best way for reporting.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-sm-12 col-md-4 m-sm-b"
        *ngFor="let content of team ? team.slice(0, 3) : []"
      >
        <div class="card border-0 text-center"></div>
      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <a href="assets/files/Educational toolkit.pdf" class="btn btn-primary"
        >Download our educational toolkit</a
      >
    </div>
  </div>
</div>
