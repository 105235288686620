<div class="section-gap">
   <div class="container">
      <div class="section-title-wrapper mb-5">
         <div class="row">
            <div class="col-sm-12 col-md-9 mx-auto text-center">
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-sm-12">
            <div class="card border-0 text-center">
             <p>
               <strong>GDPR</strong> is a regulation that requires businesses to protect the personal data and privacy
               of EU citizens for transactions that occur within EU member states. And non-compliance could cost
               companies dearly. Here's what every company that does business in Europe needs to know about GDPR.
             </p>
            </div>
            <div class="">
               <strong> The GDPR sets out seven key principles:
               </strong>
               <ul class="list ">
                  <li class="list-item ">Lawfulness, fairness and transparency</li>
                  <li class="list-item ">Purpose limitation</li>
                  <li class="list-item ">Data minimisation</li>
                  <li class="list-item ">Accuracy</li>
                  <li class="list-item ">Storage limitation</li>
                  <li class="list-item ">Integrity and confidentiality (security)</li>
                  <li class="list-item ">Accountability</li>
               </ul>
               <p>Download <a href="./assets/files/GDPR Partner Information Sheet.pdf"><strong>GDPR Partner Information
                        Sheet</strong></a>
                  and <a href="./assets/files/GDPR INFORMED CONSENT FORM.pdf"><strong>GDPR informed consent
                        form</strong></a></p>
            </div>
         </div>
      </div>
   </div>
</div>