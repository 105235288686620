<div class="page-main-container">
   <!-- Service grid -->
   <div class="service section-gap">
      <div class="container">
         <div class="section-title-wrapper">
           <div class="row">
               <div class="col-sm-12 col-md-10 mx-auto text-center">
                  <h3> Youth employment &harr; Cultural heritage</h3>
                  <p class="mb-0">Exchange of good practices, training of youth leaders, development of a European platform with online tools.</p>
               </div>
           </div>
         </div>
         <div class="row" angly-serviceGrid [serviceGridContent]="services"></div>
      </div>
   </div>
<!-- Team grid -->
<div class="team section-gap">
   <div class="container">
      <div class="section-title-wrapper">
         <div class="row">
            <div class="col-sm-12 col-md-10 mx-auto text-center">
               <h2> Partner organisations </h2>
               <p class="mb-0">Meet our partner organisations and their fields of expertise</p>
            </div>
         </div>
      </div>
      <div class="row" angly-teamGrid [teamGridContent]="team" ></div>
   </div>
</div>
<div>
   <!-- Latest Blog Section -->
<div class="blog-wrapper section-gap">
   <div class="container">
      <div class="container">
         <div class="section-title-wrapper">
            <div class="row">
               <div class="col-sm-12 col-md-10 mx-auto text-center">
                  <h2> Latest news</h2>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-sm-12 col-md-12 col-lg-6 blog-post">
            <div class="blog-wrap mb-5">
               <div class="overlay-wrap mb-4 image-container">
                  <img src="assets/img/mcmf/logos/erasmusDay.jpg" alt="blog-list"
                     class="img-fluid border-rad" />
                  <div class="card-img-overlay primary-tp-layer pos-center text-center">
                     <div class="center-holder">
                        <a routerLink="/leaflet-post" class="ih-fade-right"><i
                              class="fa fa-arrow-right fa-3x fa-inverse"></i></a>
                     </div>
                  </div>
               </div>
               <div class="blog-content col-12">
                  <h4 class="mb-3 title"><a routerLink="/leaflet-post">Erasmus day 2020</a>
                  </h4>
                  <div class="blog-meta mb-1">
                     <a href="javascript:void(0);" class="text-muted">
                        <i class="fa text-primary  fa-calendar-o mr-2 font-lg"></i>
                        <span class="font-sm">20.10.2020.</span>
                     </a>
                  </div>
                  <p class="mb-3">15 - 16 - 17 October 2020</p>
                  <a href='assets/files/Erasmus Day.pdf' class="btn btn-primary btn-md"> FIND OUT MORE </a>
               </div>
            </div>
         </div>
         <div class="col-sm-12 col-md-12 col-lg-6 blog-post">
            <div class="blog-wrap mb-5">
               <div class="overlay-wrap mb-4 image-container">
                  <img src="assets/img/mcmf/other/mockup-mcmf.jpg" alt="blog-list"
                     class="img-fluid border-rad" />
                  <div class="card-img-overlay primary-tp-layer pos-center text-center">
                     <div class="center-holder">
                        <a routerLink="/leaflet-post" class="ih-fade-right"><i
                              class="fa fa-arrow-right fa-3x fa-inverse"></i></a>
                     </div>
                  </div>
               </div>
               <div class="blog-content">
                  <h4 class="mb-3 title"><a routerLink="/leaflet-post">My Culture Heritage & My Future Brochure</a>
                  </h4>
                  <div class="blog-meta mb-1">
                     <a href="javascript:void(0);" class="text-muted">
                        <i class="fa text-primary  fa-calendar-o mr-2 font-lg"></i>
                        <span class="font-sm">10.10.2020.</span>
                     </a>
                  </div>
                  <p class="mb-3">Available in 6 languages</p>
                  <a routerLink="/leaflet-post" class="btn btn-primary btn-md"> READ MORE </a>
               </div>
            </div>
         </div>

      </div>
   </div>
</div>
</div>
   <div class="contact-section section-gap">
      <div class="container">
         <div class="section-title-wrapper">
            <div class="row">
               <div class="col-sm-12 col-md-10 mx-auto text-center">
                  <h2>Contact Information  </h2>
               </div>
            </div>
         </div>
         <div class="row" angly-homeContact [contact]="contact"></div>
      </div>
   </div>
</div>
