<div class="container" id="partners">
	<div *ngFor="let content of teamGridContent ? teamGridContent.slice(0,6):[]"
		class="organisation-container team-list">
		<div class="logo" style="background-image: url('{{content?.image_path}}');">
		</div>
		<div class="description">
			<p>{{content?.content}}</p>
		</div>
		<div class="icon-container">
			<a *ngIf=content?.website href="{{content?.website}}"><img src="./assets/img/mcmf/icons/web.svg"
					alt="Website"></a>
			<a *ngIf=content?.facebook href="{{content?.facebook}}"><img src="./assets/img/mcmf/icons/facebook.svg"
					alt="Facebook"></a>
			<a *ngIf=content?.instagram href="{{content?.instagram}}"><img src="./assets/img/mcmf/icons/instagram.svg"
					alt="Instagram"></a>
			<a *ngIf=content?.twitter href="{{content?.twitter}}"><img src="./assets/img/mcmf/icons/twitter.svg"
				alt="twitter"></a>
		</div>
	</div>
</div>