<div class="col-sm-12 col-md-12 col-lg-12 py-5 contact-home-info mx-auto text-center">
   <div><i class="ion-ios-chatboxes font-3x text-warning"></i></div>
   <div class="media-body">
      <h5 class="mb-3"> Email: </h5>
      <span *ngFor="let email of contact?.email">
         <span *ngIf="email?.status == 1">
            <a href="mailto:{{email?.value}}" class="d-block mb-1 text-dark">{{email?.value}}</a>
         </span>
      </span>
   </div>
</div>